import { Box } from "@mui/material";
import { unstable_ClassNameGenerator } from "@mui/material/className";
import { ThemeProvider } from "@mui/material/styles";
import {
  FifaThemeVariable,
  HealthcareVariable,
  LightThemeVariable,
  PrelemTheme,
} from "@platformx/themes";
import { i18next } from "@platformx/translation";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { AnalyticsProvider } from "use-analytics";
import ErrorBoundary from "../components/Common/ErrorBoundary";
import { analyticsInstance } from "../dynamic/dynamicAnalytics";
import "../styles/globals.css";
import { triggerAPM } from "../utils/helperFunctions";

const { publicRuntimeConfig } = getConfig();
unstable_ClassNameGenerator.configure((componentName) =>
  componentName.replace("Mui", "Platform-x-"),
);

function MyApp(props) {
  const { Component, pageProps, pageData } = props;
  const pageRouter = useRouter();
  const [instances, setInstances] = useState<any>({
    plugins: [],
    storage: {},
    events: {
      core: [],
      plugins: [],
    },
  });

  const siteName = publicRuntimeConfig.NEXT_SITE_BASED_THEME;
  const site_array = (siteName || "").split(",");
  let ThemeConstant;
  if (site_array?.[0] === pageProps?.site_host) {
    ThemeConstant = HealthcareVariable;
  } else if (site_array?.[1] === pageProps?.site_host) {
    ThemeConstant = LightThemeVariable;
  } else if (site_array?.[2] === pageProps?.site_host) {
    ThemeConstant = FifaThemeVariable;
  } else {
    ThemeConstant = LightThemeVariable;
  }

  const analyticHandle = () => {
    if (
      publicRuntimeConfig.NEXT_IS_ANALYTIC_ENABLE === "true" &&
      instances?.plugins?.length === 0
    ) {
      (async () => {
        const res = await analyticsInstance();
        setInstances(res);
      })();
    }
  };

  useEffect(() => {
    if (publicRuntimeConfig?.NEXT_ENVIRONMENT === "prod") triggerAPM();
  }, []);

  const CookieComponent = dynamic(() => import("../components/Cookie/Cookie"), {
    ssr: false,
  });

  //Chatbot commented - request from Bhuwan.
  // const ChatPopUp = dynamic(() => import("../components/chat/chatPopUp"), { ssr: false });

  return (
    <I18nextProvider i18n={i18next}>
      <ToastContainer position='bottom-right' />
      {/* <CacheProvider value={emotionCache}> */}
      <ThemeProvider theme={PrelemTheme(ThemeConstant)}>
        {/* <CssBaseline /> */}
        <ErrorBoundary>
          <AnalyticsProvider instance={instances}>
            <Box
              sx={{
                margin: (themeOptions) => themeOptions.prelemMargin.value,
                minHeight: "100vh",
              }}>
              {/* <ChatPopUp /> */}
              <Component {...props} {...pageProps} pageData={pageData} instances={instances} />
            </Box>
          </AnalyticsProvider>
        </ErrorBoundary>
        {!pageRouter?.asPath?.includes("embed") ? (
          <CookieComponent analyticHandle={analyticHandle} />
        ) : null}
      </ThemeProvider>
      {/* </CacheProvider> */}
    </I18nextProvider>
  );
}

export default MyApp;

import { FOOTER_ITEM, USER_EXPERIENCE } from "../constants/CommonConstants";
import getApi from "../services/graphQl/publishApi";
import { captureLogs, handleException } from "./helperFunctions";

const getHeaderFooter = async (locale, host) => {
  try {
    const [menuResponse, footerSettingModelResponse] = await Promise.all([
      getApi.fetchMenu(
        {
          pagePath: "",
        },
        locale,
        host,
      ),
      getApi.fetchFooter(
        {
          pagePath: FOOTER_ITEM,
        },
        locale,
        host,
      ),
    ]);
    const menuData = menuResponse?.data?.publish_fetchMenu;
    const footerSettingData =
      footerSettingModelResponse?.data?.publish_fetchMultiSiteFooterSettings;
    return [menuData, footerSettingData];
  } catch (error) {
    captureLogs(error, "error in getHeaderFooter");
    handleException(
      {
        fetchMenu: { pagePath: "", locale, host },
        fetchFooter: { pagePath: FOOTER_ITEM, locale, host },
      },
      { response: "NA" },
      error,
      USER_EXPERIENCE,
      "getHeaderFooter",
    );
    return [null, null];
  }
};

const getContentData = async (type, id, locale, host) => {
  try {
    const contentResponse = await getApi.fetchContentDetail(
      {
        pagePath: id,
        contentType: type,
      },
      locale,
      host,
    );

    return contentResponse?.data?.publish_contentDetail;
  } catch (error) {
    captureLogs(error, "error in getContentData");
    handleException(
      {
        fetchContentDetail: { pagePath: id, contentType: type, locale, host },
      },
      { response: "NA" },
      error,
      USER_EXPERIENCE,
      "getContentData",
    );
    return null;
  }
};

const getInitialData = async (type, id, locale, host) => {
  const [[menuData, footerSettingData], contentData] = await Promise.all([
    getHeaderFooter(locale, host),
    getContentData(type, id, locale, host),
  ]);

  return [menuData, footerSettingData, contentData];
};

const getContentSchemaData = async (type, id, locale, host) => {
  const [menuData, footerSettingData] = await getHeaderFooter(locale, host);
  const response = await getApi.fetchContentSchemaDetail(
    {
      contentType: type,
      pagePath: id,
    },
    locale,
    host,
  );
  const contentResponse = response?.data?.publish_fetchSchemaContent;
  return [contentResponse, footerSettingData, menuData];
};

export {
  getContentData,
  getContentSchemaData,
  getHeaderFooter as getHeaderFooterData,
  getInitialData,
};

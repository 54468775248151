import googleAnalytics from "@analytics/google-analytics";
import googleTagManager from "@analytics/google-tag-manager";
import snowplowPlugin from "@analytics/snowplow";
import Analytics from "analytics";
import getConfig from "next/config";
import { SNOWPLOW } from "../constants/CommonConstants";

const { publicRuntimeConfig } = getConfig();

const snowplowInstance = snowplowPlugin({
  name: SNOWPLOW.SNOWPLOW,
  collectorUrl: publicRuntimeConfig.NEXT_SNOWPLOW_COLLECTOR_URL,
  trackerSettings: {
    appId: publicRuntimeConfig.NEXT_SNOWPLOW_APP_ID,
  },
});

const googleAnalyticsInstance = [
  googleTagManager({
    containerId: "GTM-TLTP6NV",
  }),
  googleAnalytics({
    measurementIds: [publicRuntimeConfig?.NEXT_GA_ID],
  }),
];

const getPlugins = () => {
  const allowedInstances = publicRuntimeConfig.NEXT_ANALYTIC_PROVIDERS;
  const allowedInstancesArray = allowedInstances?.split(",");
  // eslint-disable-next-line no-console
  console.log("instanceENV", allowedInstances, allowedInstancesArray);
  if (allowedInstances?.length > 0) {
    let instances: any = [];
    if (allowedInstancesArray.includes("GA"))
      instances = [...instances, ...googleAnalyticsInstance];
    if (allowedInstancesArray.includes("SNOWPLOW")) instances = [...instances, snowplowInstance];
    return instances;
  } else return [];
};

export const analyticsInstance = () => {
  const analytics = Analytics({
    app: "publish-app",
    debug: true,
    plugins: getPlugins(),
  });

  return analytics;
};
